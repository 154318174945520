import React from "react"
import styled from "styled-components"

const VideoEmbed = ({children, text, paddingMobile, paddingDesktop}) => (
  <VideoWrapper paddingDesktop={paddingDesktop} paddingMobile={paddingMobile}>
    {children &&
      <div className="video-embed">
        {children}
      </div>
    }
    {text &&
      <div className="copy">
        <p>{text}</p>
      </div>
    }
  </VideoWrapper>
)

export default VideoEmbed

const VideoWrapper = styled.div`
  margin: auto;
  max-width: ${props => props.theme.section.width};
  padding: ${props => props.paddingDesktop || '50px 82px 100px'};
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoint.desktop}) {
    padding: ${props => props.paddingMobile || '30px 30px 60px'};
  }

  .video-embed {
    iframe {
      max-width: 100%;
    }
  }
`;
