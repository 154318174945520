import React from "react"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Layout from "../components/layout"
import SafetySection from "../components/safetySection"
import StartPanels from "../components/startPanels"
import TrialSection from "../components/trialSection"
import VideoEmbed from "../components/videoEmbed"
import bg from "../images/hero-3.png"

const GetStarted = () => {
  const title = "GET STARTED TODAY"
  const text =
    "Don’t waste another day chasing down texts, missed calls and paperwork. Streamline your workflow and go paperless."
  return (
    <Layout>
      <SEO {...{ title }} />
      <Banner {...{ bg, title, text }} />
      <TrialSection />
      <VideoEmbed text="Are you ready to take control of your business? Check out this quick demo video of how easy it is to use MyServicePak.">
        <iframe title="MyServicePak Demo Video" src="https://player.vimeo.com/video/452217052" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
      </VideoEmbed>
      <StartPanels />
      <SafetySection />
    </Layout>
  )
}

export default GetStarted
