import React from "react"
import styled from "styled-components"

const StartPanels = () => (
  <SiteStartPanels>
    <div className="start__panels-wrapper">
      <div className="panel panel__one">
        <h3>HAVE QUESTIONS</h3>
        <button className="btn panel__one-btn">
          <a href="mailto:info@ehsmomentum.com">
            <h6>CONTACT US</h6>
          </a>
        </button>
      </div>
      <div className="panel panel__two">
        <h3>GET STARTED NOW</h3>
        <button className="btn panel__two-btn">
          <a href="https://msp.myservicepak.com/sign_up">
            <h6>CLICK HERE</h6>
          </a>
        </button>
      </div>
      <div className="panel panel__three">
        <h3>EMAIL A REFERRAL</h3>
        <div className="email__link">
          <a href="mailto:info@ehsmomentum.com">
            <h6>INFO@EHSMOMENTUM.COM</h6>
          </a>
        </div>
      </div>
    </div>
  </SiteStartPanels>
)

export default StartPanels

const SiteStartPanels = styled.div`
  .start__panels-wrapper {
    display: flex;
    margin: auto;
    max-width: ${props => props.theme.section.width};

    h3,
    h6 {
      color: ${props => props.theme.color.white};
    }

    .panel {
      align-items: center;
      background: ${props => props.theme.color.blue__400};
      height: ${props => props.theme.panel.height};
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 4px;
      padding: 10px;
      text-align: center;
      width: 430px;
    }

    .btn {
      border: none;
      border-radius: 4px;
      cursor: pointer;
      height: 48px;
      margin-top: 30px;
      width: 170px;

      a {
        text-decoration: none;
      }

      h6 {
        font-size: ${props => props.theme.button.fontSizeXLarge};
      }
    }

    .panel__one {
      .panel__one-btn {
        background: ${props => props.theme.color.white};

        a > h6 {
          color: ${props => props.theme.color.black};
        }
      }
    }

    .panel__two {
      background: ${props => props.theme.color.red__400};

      .panel__two-btn {
        background: ${props => props.theme.color.blue__400};
      }
    }

    .panel__three {
      .email__link {
        height: 48px;
        margin-top: 30px;

        h6 {
          text-decoration: underline white;
          font-size: ${props => props.theme.font.size.medium};
        }
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.widescreen}) {
      h3 {
        font-size: 1.6rem;
      }

      .panel__three > .email__link > a > h6 {
        font-size: ${props => props.theme.font.size.small};
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      align-items: center;
      flex-direction: column;

      .panel {
        height: 200px;
        margin: 4px;
        width: 80%;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      .panel {
        margin: 0;
        width: 100%;
      }
    }
  }
`
