import React from "react"
import styled from "styled-components"
import serviceTekImg from "../images/service-tek.png"

const TrialSection = () => (
  <SiteTrialSection>
    <div className="trial-wrapper">
      <div className="copy">
        <h3>We want to help. Try it for 30 days - on us.</h3>
        <h6>
          We understand how challenging it will be for small business to recover
          from the coronavirus and we want to help.
        </h6>
        <p>
          Not only will MyServicePak allow you to manage your business remotely
          and will separate you from your competition, we are willing to give it
          away free for 30 days! And we'll give you 30 more days for free for
          each and every referral that you send our way that signs up. This is a
          way for you to help us help small businesses! If its not what you are
          looking for, just cancel. That's it!
        </p>
      </div>
      <div
        className="img"
        style={{ backgroundImage: `url(${serviceTekImg})` }}
      />
    </div>
    <p className="price">
      Introductory Price: $199/month for up to 10 users; $10/month for each
      additional user
    </p>
  </SiteTrialSection>
)

export default TrialSection

const SiteTrialSection = styled.div`
  margin: auto;
  max-width: ${props => props.theme.section.width};
  padding: 50px 82px;

  @media (max-width: ${props => props.theme.breakpoint.desktop}) {
    padding: 30px 30px 40px;
  }

  .price {
    color: ${props => props.theme.color.blue__400};
    font-size: 1.6rem;
    line-height: 1.3;
    margin-top: 20px;
    text-align: center;

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      font-size: 1.4rem;
      margin: 0;
    }
  }

  .trial-wrapper {
    align-items: center;
    display: flex;

    .copy {
      margin-right: 20px;
      width: 60%;

      h6 {
        color: ${props => props.theme.color.black};
        font-size: ${props => props.theme.font.size.medium};
        margin: 26px 0;
      }
    }

    .img {
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 300px;
      width: 40%;
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      align-items: flex-start;
      flex-direction: column;

      .copy,
      .img {
        margin: 0;
        width: 100%;
      }

      .img {
        height: 260px;
        margin-top: 30px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
      .img {
        margin-top: 10px;
      }

      p {
        font-size: ${props => props.theme.font.size.small};
      }
    }
  }
`
