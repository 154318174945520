import React from "react"
import styled from "styled-components"

const SafetySection = () => (
  <SiteSafetySection>
    <div className="safety-wrapper">
      <h2>STARTING TODAY: ONLY WORRY ABOUT THE CUSTOMERS YOU DON'T HAVE.</h2>
      <hr className="break" />
      <h3>
        NEED A SIMPLE, AFFORDABLE AND PROVEN TOOL TO HELP YOU MANAGE YOUR HEALTH
        AND SAFETY PROGRAM?
      </h3>

      <p>
        If you have safety or compliance concerns in your business, you need a
        platform and process to follow. You need a safety management system. We
        followed the same principles of simplicity and structure that we used to
        create MyServicePak, to also create MyMomentum, a powerhouse of a safety
        management system for small business.
      </p>
      <button className="btn">
        <a href="https://www.ehsmomentum.com/mymomentum-safety-software/">
          <h6>LEARN MORE</h6>
        </a>
      </button>
    </div>
  </SiteSafetySection>
)

export default SafetySection

const SiteSafetySection = styled.div`
  .safety-wrapper {
    margin: auto;
    max-width: ${props => props.theme.section.width};
    padding: 50px 65px;
    text-align: center;

    h3 {
      font-size: 1.4rem;
    }

    p {
      margin: 30px 0;
    }

    .break {
      margin: 45px 0;
    }

    .btn {
      background: ${props => props.theme.color.red__400};
      border: none;
      box-shadow: -4px 4px ${props => props.theme.color.shadowgrey};
      cursor: pointer;
      padding: 8px 28px;

      a {
        text-decoration: none;
      }

      h6 {
        font-size: ${props => props.theme.font.size.small};
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.desktop}) {
      padding: 30px;

      h2 {
        font-size: ${props => props.theme.font.size.large};
      }

      .btn > a > h6 {
        font-size: ${props => props.theme.button.fontSizeXLarge};
      }
    }
  }
`
