import React from "react"
import styled from "styled-components"

const Banner = ({ bg, title, text }) => (
  <SiteBanner>
    <div className="banner" style={{ backgroundImage: `url(${bg})` }}>
      <div className="overlay" />
      <div className="text-wrapper">
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  </SiteBanner>
)

export default Banner

const SiteBanner = styled.div`
  .banner {

      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-content: center;
    position: relative;
    text-align: center;

    .overlay {
      background: black;
      height: 200px;
      opacity: 0.6;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .text-wrapper {
      max-width: 1200px;
      padding: 10px 40px;
      width: 100%;
      z-index: 5;

      h2,
      p {
        color: ${props => props.theme.color.white};
      }

      h2 {
        margin-bottom: 20px;
      }
    }

    @media (max-width: ${props => props.theme.breakpoint.largePhone}) {
        .text-wrapper > h2 {
            font-size: ${props => props.theme.font.size.large};
        }
    }
  }
`
